<template>
    <div class="main-container">
        <div class="main-wrapper">
            <div class="first-row-wrapper">
                <div class="check-icon-container">
                    <span class="material-symbols-outlined">check_circle</span>
                </div>
                <div class="account-verificated-text-container">
                    CUENTA VERIFICADA
                </div>
            </div>
            <br>
            <div class="third-row-wrapper">
                Su cuenta ha sido verificada. Acceda al siguiente enlace para comenzar a navegar por la web: <br><br>
                <a class="web-access-url" href="/user/login"> Acceso a la web </a>
            </div>
            <br>
            <div class="fourth-row-wrapper">
                Bienvenid@ a Eurekers.
            </div>
        </div>
    </div>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
    mixins: [APICaller],
    name: 'AccountVerification',
    components: {
    },
    data(){
        return{
            user_email: '',
        }
    },
    created(){
        var isMenuSet = setTimeout(() => {
            if(document.getElementById('menu-container') != undefined){
                this._removePrimaryMenu();
                clearInterval(isMenuSet);
            }
        }, 50);
    },
    mounted(){

    },
    computed: {

    },
    methods: {
        _removePrimaryMenu(){
            document.getElementById('menu-container').style.display = "none";
        }
    },
}
</script>

<style scoped>

    .main-container{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .first-row-wrapper{
        color: green;
        border: 2px solid green;
        padding: 10px;
        width: 50%;
        margin: auto;
    }

    .third-row-wrapper a{
        color: #0ad;
    }

</style>